import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./layout.css";

interface LayoutProps {
  className?: string;
}
const Layout: React.FunctionComponent<LayoutProps> = ({
  children,
  className,
}) => {
  return <div className={cx("w-full h-full", className)}>{children}</div>;
};

export default Layout;
